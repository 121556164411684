import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components'

import { AMAZON_MWS_PPC_SYNC_STEPS } from 'constants/account'
import { MWS_SYNC_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/account/mwsSync'

import { COLORS } from 'COLORS'
import { closeMwsSyncModal, showMwsSyncModal } from 'actions/account/mwsSync'
import { Modal } from '../../../components/common/junglekit/ui_elements/Modal/Modal'
import { HeaderTutorialIcon } from '../../../components/account/ConnectToMwsPpcSteps/Abstract'
import MwsSyncSteps from '../MwsSyncSteps'

const StyledModal = styled(Modal)`
  max-height: 99%;
  overflow: initial;
`

export class MwsAndPpcSyncModals extends Component {
  onBackdropClick() {
    this.props.closeMwsSyncModal()
  }

  onTutorialClick() {
    this.props.showMwsSyncModal(
      AMAZON_MWS_PPC_SYNC_STEPS.tutorialModal,
      this.props.isUpgrade
    )
  }

  renderHeader() {
    const { membershipInfo, activeModal, t, noHeader } = this.props
    if (noHeader) {
      return false
    }

    const headerText =
      membershipInfo && membershipInfo.group === 'Consolidated'
        ? t(
            'account:ConnectToAmazonModals.Abstract.header_launch',
            'Unlock Launch'
          )
        : t(
            'account:ConnectToAmazonModals.Abstract.header_seller_features',
            'Connect to Amazon'
          )

    return (
      <span>
        {headerText}
        {activeModal !== AMAZON_MWS_PPC_SYNC_STEPS.tutorialModal && (
          <HeaderTutorialIcon
            feature="TODO"
            video="TODO"
            onClick={() => this.onTutorialClick()}
            dataId={MWS_SYNC_DATA_ATTRIBUTE_IDS.Buttons.MwsModal.Tutorial}
            disableModal
          />
        )}
      </span>
    )
  }

  render() {
    if (!this.props.activeModal) {
      return null
    }

    return (
      <StyledModal
        isVisible={!!this.props.activeModal}
        closeIconColor={COLORS.grey900}
        title={this.renderHeader()}
        handleBackdropClick={() => this.onBackdropClick()}>
        <MwsSyncSteps />
      </StyledModal>
    )
  }
}

const mapStateToProps = ({
  account: {
    ui: {
      mwsSync: { isUpgrade, activeModal, noHeader }
    }
  },
  globalData: { membershipInfo }
}) => {
  return { isUpgrade, activeModal, membershipInfo, noHeader }
}

export default withTranslation(['account'])(
  connect(
    mapStateToProps,
    {
      closeMwsSyncModal,
      showMwsSyncModal
    }
  )(MwsAndPpcSyncModals)
)
